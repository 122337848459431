const env = import.meta.env.VITE_NODE_ENV
const PROD = env === 'prod'

export const employee = {
  DEFAULT_MANAGED_BY: {
    SHIPPER: PROD ? 2491 : 3, // PROD: PRIYADHARSHINI M
    CARRIER: PROD ? 2479 : 3  // PROD: VIKAS H
  },
  CONTACT: {
    SHARE: {
      name: "",
      mobile: "+971 509408799" // VIKAS H 
    }
  },
  ROLE: {
    ADMIN: 1,
    OPS: 2,
    SALES: 3,
    FINANCE: 4,
    OPS_MANAGER: 5,
    ONBOARDING: 6
  },
  GET_ROLE_NAME: {
    "1": "Admin",
    "2": "Operations",
    "3": "Sales",
    "4": "Finance",
    "5": "Ops Manager",
    "6": "Onboarding",
  },
  COUNTRY: {
    "1": "United Arab Emirates",
    "2": "Saudi Arabia",
    "3": "Oman",
    "4": "Qatar",
    "5": "Kuwait",
    "6": "Bahrain",
    "7": "Gaza",
    "8": "Jordan",
    "9": "Yemen"
  }
}
