export const company = {
  COMPANY_STATUS_ID: {
    Lead: 1,
    Active: 2,
    "In-Active": 3,
    Blacklisted: 4,
    RequestedAccountDelete : 5,
    Rejected: 6,
    KYCSubmitted: 7
  },
  COMPANY_STATUS_NAME: {
    "Lead": "Lead",
    "Active": "Active",
    "In-Active": "In-Active",
    "Blacklisted": "Blacklisted",
    "RequestedAccountDelete": "RequestedAccountDelete",
    "Rejected": "Rejected",
    "KYC_Submitted": "KYC Submitted"
  },
  USER_TYPE: {
    CARRIER: 'carrier' as 'carrier',
    SHIPPER: 'shipper' as 'shipper',
    EMPLOYEE: 'employee' as 'employee',
    ADMIN: 'admin' as 'admin'
  },
  OPERATION_TYPE: {
    LOCAL: 'Local',
    CROSS_BORDER: 'Cross border'
  },
  TOKEN_PROCESS_ID: {
    "CARRIER_ADVANCE": 1,
    "SHIPPER_ADVANCE": 2,
    "TRIP_INVOICE": 3,
    "CARRIER_BALANCE": 4,
    "SHIPPER_BALANCE": 5,
    "SHIPPER_WALLET_TOPUP": 6,
    "SHIPPER_WALLET_REVERSE": 7,
    "CANCEL_TRIP_INVOICE": 8,
    "CANCEL_SHIPPER_ADVANCE": 9,
    "TRIP_CHARGE_APPROVE": 10
  }
}

export const contacts = {
  DEFAULT_CONTACT: {
    UAE_SUPPORT: '+97142381385'
  }
}