export const document = {
    DOCUMENT_TYPE: {
        "RC": { id: 1, name: "RC" },
        "EMIRATES_ID": { id: 2, name: "EMIRATES_ID" },
        "PASSPORT": { id: 3, name: "PASSPORT" },
        "DRIVING_LICENSE": { id: 4, name: "DRIVING_LICENSE" },
        "CONSIGNMENT_NOTE": { id: 5, name: "CONSIGNMENT_NOTE" },
        "DELIVERY_NOTE": { id: 6, name: "DELIVERY_NOTE" },
        "TRADE_LICENSE" : { id : 7 , name : 'TRADE LICENSE'},
        "VAT_CERTIFICATE" : {id : 8 , name : 'VAT CERTIFICATE'},
        "VISA" : {id : 9 , name : 'VISA'},
        "MULKIYA_FB" : {id : 10 , name : 'MULKIYA F/B'},
        "EVIDENCE" : {id : 11 , name : 'EVIDENCE'},
    },
    DOCUMENT_TYPE_BY_ID: {
      "1": "RC" ,
      "2": "EMIRATES_ID",
      "3": "PASSPORT",
      "4": "DRIVING_LICENSE",
      "5": "CONSIGNMENT_NOTE",
      "6": "DELIVERY_NOTE",
      "7": 'TRADE LICENSE',
      "8": 'VAT CERTIFICATE',
      "9": 'VISA',
      "10": 'MULKIYA F/B',
      "11": 'EVIDENCE'
  },
    DOCUMENT_STATUS: {
        "Approved": { id: 1, name: "Approved" },
        "Pending": { id: 2, name: "Pending" },
        "Rejected": { id: 3, name: "Rejected" }
    },
    TRIP_FILE_TYPE : {
        CONSIGNMENT_NOTE:"CONSIGNMENT_NOTE",
        DELIVERY_NOTE:"DELIVERY_NOTE",
        EVIDENCE: "EVIDENCE"
    }
}