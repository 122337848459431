
import { company, contacts } from "./company"
import { countryCode } from "./countryCode"
import { document } from "./document"
import { employee } from "./employee"
import { lead } from "./lead"
import { postgresErrorCodes } from "./postgresErrorCodes"
import { route } from "./routes"
import {trip} from './trip'
import { doctype } from './doctype'
import { bank } from "./bank"
import { division} from "./division"
import { truck } from "~/utils/constants/truck"

export const constants = {
    ...route,
    ...company,
    ...lead,
    ...contacts,
    ...employee,
    country_code: countryCode,
    ...postgresErrorCodes,
    ...document,
    ...trip,
    ...doctype,
    ...bank,
    ...division,
    ...truck
}