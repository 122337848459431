export const truck: any = {
  KYC_STATUS_STYLE: {
    "Lead": "warning",
    "Unallocated": "info",
    "Allocated": "success",
    "Deactivated": "danger"
  },
  TRAFFIC_CODE: {
    '1': { id: '1', name: 'Abu Dhabi', code: 'AD' },
    '2': { id: '2', name: 'Ajman', code: 'AJM' },
    '3': { id: '3', name: 'Bahrain', code: 'BAH' },
    '4': { id: '4', name: 'Dubai', code: 'DXB' },
    '5': { id: '5', name: 'Fujairah', code: 'FUJ' },
    '6': { id: '6', name: 'Gaza', code: 'PSE' },
    '7': { id: '7', name: 'Jordan', code: 'JOR' },
    '8': { id: '8', name: 'Kuwait', code: 'KWT' },
    '9': { id: '9', name: 'Oman', code: 'OMN' },
    '10': { id: '10', name: 'Qatar', code: 'QAT' },
    '11': { id: '11', name: 'Ras Al-Khaimah', code: 'RAK' },
    '12': { id: '12', name: 'KSA', code: 'KSA' },
    '13': { id: '13', name: 'Sharjah', code: 'SHJ' },
    '14': { id: '14', name: 'Umm Al Quwain', code: 'UAQ' },
    '15': { id: '15', name: 'Yemen', code: 'YEM' }
  }
}